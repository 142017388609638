// extracted by mini-css-extract-plugin
export var arrowIcon = "player-merge-modal-module--arrowIcon--7031f";
export var bodyBase = "player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var bodyLarge = "player-merge-modal-module--body-large--a2e3d player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var bodyLargeBold = "player-merge-modal-module--body-large-bold--183dd player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var bodyRegular = "player-merge-modal-module--body-regular--52b86 player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var bodyRegularBold = "player-merge-modal-module--body-regular-bold--6df94 player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var bodySmall = "player-merge-modal-module--body-small--99235 player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var bodySmallBold = "player-merge-modal-module--body-small-bold--34dde player-merge-modal-module--body-base--f0111 player-merge-modal-module--site-font--8d238";
export var borderTop = "player-merge-modal-module--border-top--e027f";
export var breakWordContainer = "player-merge-modal-module--break-word-container--c7082";
export var btnCancel = "player-merge-modal-module--btnCancel--a21b9";
export var btnConfirm = "player-merge-modal-module--btnConfirm--85932";
export var buttonIconBase = "player-merge-modal-module--button-icon-base--35746";
export var cardsContainer = "player-merge-modal-module--cardsContainer--8d07c";
export var checkbox = "player-merge-modal-module--checkbox--1a177";
export var checkboxLabel = "player-merge-modal-module--checkboxLabel--b0fa3";
export var checkboxRoot = "player-merge-modal-module--checkboxRoot--4813c";
export var clickLink = "player-merge-modal-module--click-link--13797";
export var dropdownBase = "player-merge-modal-module--dropdown-base--cfb3d";
export var dropdownSelectBase = "player-merge-modal-module--dropdown-select-base--b1ff4 player-merge-modal-module--text-input--93519";
export var flexCol = "player-merge-modal-module--flex-col--41070";
export var formErrorMessage = "player-merge-modal-module--form-error-message--9333a";
export var h3 = "player-merge-modal-module--h3--24d3c";
export var h4 = "player-merge-modal-module--h4--17afa";
export var hoverLink = "player-merge-modal-module--hover-link--a8806";
export var hoverRow = "player-merge-modal-module--hover-row--8e762";
export var membershipContainer = "player-merge-modal-module--membership-container--8b084 player-merge-modal-module--flex-col--41070 player-merge-modal-module--primary-border--53962";
export var membershipHeader = "player-merge-modal-module--membership-header--3c8a7";
export var membershipHeading = "player-merge-modal-module--membership-heading--5843b";
export var membershipLabel = "player-merge-modal-module--membership-label--7745d";
export var moreFiltersBorderClass = "player-merge-modal-module--more-filters-border-class--b78ee";
export var pageBg = "player-merge-modal-module--page-bg--d67a0";
export var pointer = "player-merge-modal-module--pointer--2884d";
export var primaryBorder = "player-merge-modal-module--primary-border--53962";
export var shadowBoxLight = "player-merge-modal-module--shadow-box-light--f9fb5";
export var siteFont = "player-merge-modal-module--site-font--8d238";
export var slideDownAndFade = "player-merge-modal-module--slideDownAndFade--845c2";
export var slideLeftAndFade = "player-merge-modal-module--slideLeftAndFade--ce87a";
export var slideRightAndFade = "player-merge-modal-module--slideRightAndFade--b0427";
export var slideUpAndFade = "player-merge-modal-module--slideUpAndFade--294e0";
export var statusDecoration = "player-merge-modal-module--status-decoration--a7b6b";
export var textInput = "player-merge-modal-module--text-input--93519";
export var textInverted = "player-merge-modal-module--text-inverted--f798a";
export var textMediumDark = "player-merge-modal-module--text-medium-dark--91386";
export var tooltipFont = "player-merge-modal-module--tooltipFont--f62fd";
export var unstyledButton = "player-merge-modal-module--unstyled-button--99972";