import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ClassNamesProps } from 'src/types';

import * as styles from './record-transfer-card.module.less';

type RecordType = 'moving' | 'destination';

type ClassKeys =
  | 'container'
  | 'header'
  | 'recordTitle'
  | 'headerDetailsWrapper'
  | 'headerDetail'
  | 'bodyWrapper'
  | 'bodySection'
  | 'bodySectionLabel'
  | 'bodySectionValue';

interface CardBodyDetail {
  label: string;
  value: number | string;
}
export interface Props extends ClassNamesProps<ClassKeys> {
  recordType: RecordType;
  recordTitle: string;
  headerDetails?: string[];
  bodyDetails: CardBodyDetail[];
}

export const RecordTransferCard = ({ classNames, recordType, recordTitle, headerDetails, bodyDetails }: Props) => {
  const { t } = useTranslation();

  return (
    <article className={cx(styles.container, classNames?.container)}>
      <header className={cx(styles.header, classNames?.header)}>
        <h3 className={styles.type}>{t(`mergePlayers.recordCard.type.${recordType}`)}</h3>
        <h4 className={cx(styles.recordTitle, classNames?.recordTitle)}>{recordTitle}</h4>
        {headerDetails && headerDetails.length > 0 && (
          <ul className={cx(styles.topDetailsWrapper, classNames?.headerDetailsWrapper)}>
            {headerDetails.map((detail, i) => (
              <li key={`${detail}-${i}`} className={cx(styles.topDetail, classNames?.headerDetail)}>
                {detail}
              </li>
            ))}
          </ul>
        )}
      </header>
      {
        <dl className={cx(styles.bodyWrapper, classNames?.bodyWrapper)}>
          {bodyDetails.map(({ label, value }, i) => (
            <div key={`${label}-${i}`} className={cx(styles.bodySection, classNames?.bodySection)}>
              <dt className={cx(styles.bodySectionLabel, classNames?.bodySectionLabel)}>{label}</dt>
              <dd className={cx(styles.bodySectionValue, classNames?.bodySectionValue)}>{value}</dd>
            </div>
          ))}
        </dl>
      }
    </article>
  );
};

type ClassKeysMini = 'wrapper' | 'container' | 'recordTitle' | 'detailsWrapper' | 'detail';

export interface PropsMini extends ClassNamesProps<ClassKeysMini> {
  recordType: RecordType;
  recordTitle: string;
  details?: string[];
  hideLabel?: boolean;
}

export const RecordTransferCardMini = ({ classNames, recordType, recordTitle, details, hideLabel }: PropsMini) => {
  const { t } = useTranslation();

  return (
    <article className={cx(styles.mini, classNames?.wrapper)}>
      {!hideLabel && <h3 className={styles.type}>{t(`mergePlayers.recordCard.type.${recordType}`)}</h3>}
      <div className={cx(styles.containerMini, styles[recordType], classNames?.container)}>
        <h4 className={cx(styles.recordTitle, classNames?.recordTitle)}>{recordTitle}</h4>
        {details && details.length > 0 && (
          <ul className={cx(styles.topDetailsWrapper, classNames?.detailsWrapper)}>
            {details.map((detail, i) => (
              <li key={`${detail}-${i}`} className={cx(styles.topDetail, classNames?.detail)}>
                {detail}
              </li>
            ))}
          </ul>
        )}
      </div>
    </article>
  );
};
