// extracted by mini-css-extract-plugin
export var bodyBase = "player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var bodyLarge = "player-merge-module--body-large--a7d21 player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var bodyLargeBold = "player-merge-module--body-large-bold--c2812 player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var bodyRegular = "player-merge-module--body-regular--e375e player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var bodyRegularBold = "player-merge-module--body-regular-bold--93145 player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var bodySmall = "player-merge-module--body-small--bebfd player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var bodySmallBold = "player-merge-module--body-small-bold--0ac54 player-merge-module--body-base--d6530 player-merge-module--site-font--7081e";
export var borderTop = "player-merge-module--border-top--45a6a";
export var breakWordContainer = "player-merge-module--break-word-container--1ce78";
export var buttonIconBase = "player-merge-module--button-icon-base--e18f3";
export var clickLink = "player-merge-module--click-link--4bc2d";
export var container = "player-merge-module--container--f9c03";
export var dropdownBase = "player-merge-module--dropdown-base--f88c5";
export var dropdownSelectBase = "player-merge-module--dropdown-select-base--b4c1d player-merge-module--text-input--dcd42";
export var flexCol = "player-merge-module--flex-col--a2cec";
export var formErrorMessage = "player-merge-module--form-error-message--633b1";
export var h3 = "player-merge-module--h3--c6640";
export var h4 = "player-merge-module--h4--1646b";
export var hoverLink = "player-merge-module--hover-link--789a1";
export var hoverRow = "player-merge-module--hover-row--f5382";
export var linksContainer = "player-merge-module--linksContainer--f9720";
export var membershipContainer = "player-merge-module--membership-container--fdaba player-merge-module--flex-col--a2cec player-merge-module--primary-border--e4717";
export var membershipHeader = "player-merge-module--membership-header--f4f55";
export var membershipHeading = "player-merge-module--membership-heading--91629";
export var membershipLabel = "player-merge-module--membership-label--90cb6";
export var mergeBtn = "player-merge-module--mergeBtn--5d243";
export var moreFiltersBorderClass = "player-merge-module--more-filters-border-class--187ee";
export var outerContainer = "player-merge-module--outerContainer--5593a";
export var pageBg = "player-merge-module--page-bg--19795";
export var pointer = "player-merge-module--pointer--4e0e9";
export var primaryBorder = "player-merge-module--primary-border--e4717";
export var shadowBoxLight = "player-merge-module--shadow-box-light--82541";
export var siteFont = "player-merge-module--site-font--7081e";
export var slideDownAndFade = "player-merge-module--slideDownAndFade--fb572";
export var slideLeftAndFade = "player-merge-module--slideLeftAndFade--58378";
export var slideRightAndFade = "player-merge-module--slideRightAndFade--fd540";
export var slideUpAndFade = "player-merge-module--slideUpAndFade--8edd2";
export var statusDecoration = "player-merge-module--status-decoration--32d7c";
export var textInput = "player-merge-module--text-input--dcd42";
export var textInverted = "player-merge-module--text-inverted--31b5a";
export var textMediumDark = "player-merge-module--text-medium-dark--f2405";
export var tooltipFont = "player-merge-module--tooltipFont--d4269";
export var transferCard = "player-merge-module--transferCard--368bc";
export var unstyledButton = "player-merge-module--unstyled-button--e9243";