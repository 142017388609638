// extracted by mini-css-extract-plugin
export var bodyBase = "record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodyLarge = "record-transfer-card-module--body-large--1b21d record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodyLargeBold = "record-transfer-card-module--body-large-bold--62f63 record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodyRegular = "record-transfer-card-module--body-regular--67938 record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodyRegularBold = "record-transfer-card-module--body-regular-bold--c8454 record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodySection = "record-transfer-card-module--bodySection--41ea0";
export var bodySectionLabel = "record-transfer-card-module--bodySectionLabel--88eb0";
export var bodySectionValue = "record-transfer-card-module--bodySectionValue--9a666";
export var bodySmall = "record-transfer-card-module--body-small--311ef record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodySmallBold = "record-transfer-card-module--body-small-bold--28246 record-transfer-card-module--body-base--18d97 record-transfer-card-module--site-font--7af0a";
export var bodyWrapper = "record-transfer-card-module--bodyWrapper--54d06";
export var borderTop = "record-transfer-card-module--border-top--69d70";
export var breakWordContainer = "record-transfer-card-module--break-word-container--c3252";
export var buttonIconBase = "record-transfer-card-module--button-icon-base--b5729";
export var clickLink = "record-transfer-card-module--click-link--006cb";
export var container = "record-transfer-card-module--container--b9506";
export var containerMini = "record-transfer-card-module--containerMini--253a0";
export var detailsWrapperMini = "record-transfer-card-module--detailsWrapperMini--74c7c";
export var dropdownBase = "record-transfer-card-module--dropdown-base--82e04";
export var dropdownSelectBase = "record-transfer-card-module--dropdown-select-base--ef6f0 record-transfer-card-module--text-input--fbf83";
export var flexCol = "record-transfer-card-module--flex-col--9a4fe";
export var formErrorMessage = "record-transfer-card-module--form-error-message--2fbd3";
export var h3 = "record-transfer-card-module--h3--b8bc2";
export var h4 = "record-transfer-card-module--h4--deabd";
export var header = "record-transfer-card-module--header--c2de3";
export var hoverLink = "record-transfer-card-module--hover-link--8fa4d";
export var hoverRow = "record-transfer-card-module--hover-row--e5a03";
export var membershipContainer = "record-transfer-card-module--membership-container--d7563 record-transfer-card-module--flex-col--9a4fe record-transfer-card-module--primary-border--bb67c";
export var membershipHeader = "record-transfer-card-module--membership-header--2fd69";
export var membershipHeading = "record-transfer-card-module--membership-heading--ce635";
export var membershipLabel = "record-transfer-card-module--membership-label--8070f";
export var mini = "record-transfer-card-module--mini--c4cd1";
export var moreFiltersBorderClass = "record-transfer-card-module--more-filters-border-class--e772a";
export var moving = "record-transfer-card-module--moving--9a368";
export var pageBg = "record-transfer-card-module--page-bg--7e85e";
export var pointer = "record-transfer-card-module--pointer--aa336";
export var primaryBorder = "record-transfer-card-module--primary-border--bb67c";
export var recordTitle = "record-transfer-card-module--recordTitle--8b62d";
export var shadowBoxLight = "record-transfer-card-module--shadow-box-light--19d37";
export var siteFont = "record-transfer-card-module--site-font--7af0a";
export var slideDownAndFade = "record-transfer-card-module--slideDownAndFade--53e02";
export var slideLeftAndFade = "record-transfer-card-module--slideLeftAndFade--7b1ec";
export var slideRightAndFade = "record-transfer-card-module--slideRightAndFade--27af3";
export var slideUpAndFade = "record-transfer-card-module--slideUpAndFade--96a50";
export var statusDecoration = "record-transfer-card-module--status-decoration--acc15";
export var textInput = "record-transfer-card-module--text-input--fbf83";
export var textInverted = "record-transfer-card-module--text-inverted--2aec1";
export var textMediumDark = "record-transfer-card-module--text-medium-dark--28d9f";
export var tooltipFont = "record-transfer-card-module--tooltipFont--6bed7";
export var topDetail = "record-transfer-card-module--topDetail--bc48a";
export var topDetailsWrapper = "record-transfer-card-module--topDetailsWrapper--da8f4";
export var type = "record-transfer-card-module--type--8405a";
export var unstyledButton = "record-transfer-card-module--unstyled-button--dd56f";